import * as THREE from 'three';
import gsap from 'gsap';
import vertexShader from '~/webgl/shaders/vertex.glsl?raw';
import fragmentShader from '~/webgl/shaders/fragment.glsl?raw';
import type {
  FloatingPlaneParameters,
  FloatingPlaneItem,
  FloatingPlaneDataImage,
  FloatingPlaneDataVideo
} from '~/webgl/floating-planes/FloatingPlanes';
import isTouchDevice from '~/utils/isTouchDevice';

export default function FloatingPlane(
  data: FloatingPlaneDataImage | FloatingPlaneDataVideo,
  index: number,
  worldSizes: { width: number; height: number },
  initialParameters: FloatingPlaneParameters = {
    fadeOutPoint: 0.965,
    maxBlur: 7
  }
): FloatingPlaneItem {
  const isTouch = isTouchDevice();
  const isDebug = false;
  let parameters = initialParameters;

  const status = {
    isIntersecting: false,
    currentPositionZ: 0,
    progress: 0,
    isFadedOut: false
  };

  const floatingGroup = new THREE.Group();

  const scale =
    data.assetData.aspectRatio > 1
      ? {
          x: 1.35,
          y: (1.35 / data.assetData.width) * data.assetData.height
        }
      : {
          x: (1 / data.assetData.height) * data.assetData.width,
          y: 1
        };

  const sizesByMediaQuery = {
    '(max-width: 800px) and (orientation : landscape)': 0.8,
    '(max-width: 800px) and (orientation : portrait)': 0.95,
    '(min-width: 800px) and (orientation : landscape)': 1.3,
    '(min-width: 800px) and (orientation : portrait)': 0.7
  };

  const maxSize = worldSizes.height * findSizeByCurrentMediaQuery();

  const geometry = new THREE.PlaneGeometry(maxSize * scale.x, maxSize * scale.y, 32, 32);

  const intersectionPlane = new THREE.Mesh(
    geometry.clone(),
    new THREE.MeshBasicMaterial({
      transparent: true,
      color: 'white',
      opacity: isDebug ? 0.5 : 0
    })
  );

  const focus = new THREE.Mesh(
    geometry.clone(),
    new THREE.MeshBasicMaterial({
      color: 0xffffff,
      map: data.focusTexture,
      transparent: true,
      opacity: 0
    })
  );

  const material = getPlaneMaterial();

  const mesh = new THREE.Mesh(geometry, material);

  const intersection = Intersection();
  intersection.stop();

  const fade = Fade();

  floatingGroup.add(mesh);
  floatingGroup.add(intersectionPlane);

  // if (!isTouchDevice()) {
  //   floatingGroup.add(focus);
  // }
  floatingGroup.add(focus);

  if (window.innerWidth > 800) {
    intersectionPlane.scale.set(0.5, 0.5, 0.5);
  }

  intersectionPlane.position.z = 0.05;
  intersectionPlane.layers.enable(1);

  focus.position.z = 0.01;

  return {
    group: floatingGroup,
    mesh,
    intersectionPlane,
    focus,
    status,
    data,
    update,
    init,
    onIntersecting,
    onClick
  };

  function onClick() {
    // window.location.href = data.link;
  }

  function init(zSegmentLength: number, offsetX: number, offsetY: number) {
    const offsetFactor = window.innerWidth < 800 ? 1.5 : 1;
    const z = zSegmentLength * index;

    floatingGroup.userData.zInitial = z;
    floatingGroup.position.z = floatingGroup.userData.zInitial;

    floatingGroup.userData.xInitial = offsetX * offsetFactor;
    floatingGroup.position.x = floatingGroup.userData.xInitial;

    floatingGroup.userData.yInitial = offsetY * offsetFactor;
    floatingGroup.position.y = floatingGroup.userData.yInitial;
  }

  function update(
    zProgress: number,
    zLength: number,
    opacity: number,
    parallax: { x: number; y: number },
    log: boolean
  ) {
    floatingGroup.position.z = (floatingGroup.userData.zInitial + zProgress) % zLength;

    status.currentPositionZ = floatingGroup.position.z;
    status.progress = floatingGroup.position.z / zLength;

    floatingGroup.position.x = (1 - status.progress - 0.035) * floatingGroup.userData.xInitial;
    floatingGroup.position.y = (1 - status.progress - 0.035) * floatingGroup.userData.yInitial;

    if (!isTouch) {
      const centerX = 0 - floatingGroup.position.x;
      const centerY = 0 - floatingGroup.position.y;

      focus.position.x = (parallax.x + centerX * 15) * 0.005;
      focus.position.y = (parallax.y - centerY * 15) * -0.005;
    }

    if (status.progress >= parameters.fadeOutPoint) {
      fade.fadeOut();
      intersection.stop();
    } else {
      fade.fadeIn();
    }
  }

  function Fade() {
    return {
      fadeIn,
      fadeOut
    };

    function fadeIn() {
      if (!status.isFadedOut) {
        return;
      }

      status.isFadedOut = false;

      if (mesh.material instanceof THREE.ShaderMaterial) {
        gsap.to(mesh.material.uniforms.uOpacity, {
          value: 1
        });
      } else {
        gsap.to(mesh.material, {
          opacity: 1
        });
      }
    }

    function fadeOut() {
      if (status.isFadedOut) {
        return;
      }

      status.isFadedOut = true;

      if (mesh.material instanceof THREE.ShaderMaterial) {
        gsap.to(mesh.material.uniforms.uOpacity, {
          value: 0
        });
      } else {
        gsap.to(mesh.material, {
          opacity: 0
        });
      }
    }
  }

  function onIntersecting(isIntersecting: boolean) {
    const currentOpacity =
      mesh.material instanceof THREE.ShaderMaterial
        ? mesh.material.uniforms.uOpacity.value
        : mesh.material.opacity;

    if (currentOpacity < 0.9) {
      if (status.isIntersecting === true && isIntersecting === false) {
        status.isIntersecting = false;
        intersection.stop();
      }
      return;
    }

    if (status.isIntersecting === true && isIntersecting === false) {
      status.isIntersecting = false;
      intersection.stop();
    } else if (status.isIntersecting === false && isIntersecting === true) {
      status.isIntersecting = true;
      intersection.start();
    }
  }

  function Intersection() {
    return {
      start,
      stop
    };

    function start() {
      const tl = gsap.timeline({
        paused: true
      });

      if (material instanceof THREE.ShaderMaterial) {
        tl.to(material.uniforms.uBlurStrength, {
          value: () => parameters.maxBlur,
          duration: 0.6
        }).to(material.uniforms.uBlurStrength, {
          value: 0,
          duration: 0.2
        });
      }

      tl.play(0);

      focus.material.opacity = 1;

      if (isDebug) {
        intersectionPlane.material.color = new THREE.Color('red');
      }
    }

    function stop() {
      if (material instanceof THREE.ShaderMaterial) {
        gsap.to(material.uniforms.uBlurStrength, {
          value: 0,
          duration: 0.15
        });
      }

      focus.material.opacity = 0;

      if (isDebug) {
        intersectionPlane.material.color = new THREE.Color('white');
      }
    }
  }

  function getPlaneMaterial() {
    if (data.videoTexture) {
      data.videoTexture.colorSpace = THREE.SRGBColorSpace;

      return new THREE.MeshBasicMaterial({
        color: '#ffffff',
        map: data.videoTexture,
        transparent: true
      });
    }

    return new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent: true,
      depthTest: true,
      depthWrite: true,
      uniforms: {
        uTexture: {
          value: data.imageTexture
        },
        uTextureBlur: {
          value: data.blurryImageTexture
        },
        uBlurStrength: {
          value: 0
        },
        uOpacity: {
          value: 1
        },
        uResolution: {
          value: new THREE.Vector2(data.assetData.width * 2, data.assetData.height * 2)
        }
      }
    });
  }

  function findSizeByCurrentMediaQuery() {
    const record = Object.entries(sizesByMediaQuery).find(function ([query, size]) {
      return window.matchMedia(query).matches;
    });

    if (record) {
      return record[1];
    }

    return 1;
  }
}
