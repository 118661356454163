import * as THREE from 'three';
import useCursorPosition, { type Cursor } from '~/webgl/useCursorPosition';

export type Sizes = {
  width: number;
  height: number;
};

export type ThreeBase = {
  renderer: THREE.WebGLRenderer;
  sizes: Sizes;
  getWorldSizes: () => Sizes;
  addOnTick: (f: (dT: number, eT: number, intersections: THREE.Intersection[]) => void) => void;
  time: { previousTime: number; clock: THREE.Clock };
  camera: THREE.PerspectiveCamera;
  updateSizes: () => void;
  setIntersectionsObjects: (objects: THREE.Object3D[]) => void;
  scene: THREE.Scene;
  canvas: HTMLCanvasElement;
  cursor: Cursor;
};

export default function ThreeBase(canvas: HTMLCanvasElement): ThreeBase {
  const raycaster = new THREE.Raycaster(
    new THREE.Vector3(0.0, 0.0, 0.0), // default
    new THREE.Vector3(0, 0, -1), // default
    1,
    3
  );
  raycaster.layers.set(1);

  const pointer = new THREE.Vector2();
  let intersectionObjects: THREE.Object3D[] = [];

  const sizes: Sizes = {
    width: canvas.clientWidth,
    height: canvas.clientHeight
  };

  const { cursor, updateSizes: updateCursorSizes } = useCursorPosition(sizes);

  const time = {
    clock: new THREE.Clock(),
    previousTime: 0
  };

  const scene = new THREE.Scene();

  const renderer = new THREE.WebGLRenderer({
    antialias: false,
    alpha: false,
    canvas
  });

  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

  const distance = 1;
  const camera = new THREE.PerspectiveCamera(distance, sizes.width / sizes.height, 0.2, 5);
  camera.position.set(0, 0, distance);

  const runningOnTick: ((dT: number, eT: number, intersections: THREE.Intersection[]) => void)[] =
    [];

  let tickRequest: number | null;

  addIntersectionObserver(
    () => {
      startTick();
      if (isTouchDevice()) {
        document.body.addEventListener('click', onClick);
      }
      document.body.addEventListener('pointermove', onPointerMove);
    },
    () => {
      stopTick();
      document.body.removeEventListener('pointermove', onPointerMove);
    }
  );

  window.addEventListener('resize', onWindowResize);

  onWindowResize();

  return {
    time,
    scene,
    renderer,
    sizes,
    camera,
    canvas,
    cursor,
    getWorldSizes,
    updateSizes,
    addOnTick,
    setIntersectionsObjects
  };

  function onWindowResize() {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;

    updateSizes();
    updateCursorSizes(sizes);
  }

  function addIntersectionObserver(onEnter: () => void, onLeave: () => void) {
    const options = {
      rootMargin: '0px',
      threshold: 0.5
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    observer.observe(canvas);

    function handleIntersect(entries: IntersectionObserverEntry[]) {
      if (!entries.length) {
        return;
      }
      const isIntersecting = entries.every((e) => e.isIntersecting);
      if (isIntersecting) {
        onEnter();
      } else {
        onLeave();
      }
    }
  }

  function setIntersectionsObjects(objects: THREE.Object3D[]) {
    intersectionObjects = [...objects];
  }

  function startTick() {
    if (!tickRequest) {
      tick();
    }
  }

  function stopTick() {
    if (tickRequest) {
      window.cancelAnimationFrame(tickRequest);
      tickRequest = null;
    }
  }

  function addOnTick(f: (dT: number, eT: number, intersections: THREE.Intersection[]) => void) {
    runningOnTick.push(f);
  }

  function updateSizes() {
    sizes.width = canvas.clientWidth;
    sizes.height = canvas.clientHeight;

    updateCamera(sizes);
    updateRenderer(sizes);
  }

  function tick() {
    const elapsedTime = time.clock.getElapsedTime();
    const deltaTime = elapsedTime - time.previousTime;
    time.previousTime = elapsedTime;

    raycaster.setFromCamera(pointer, camera);
    const intersects = raycaster.intersectObjects(intersectionObjects, false);

    runningOnTick.forEach((f) => f(deltaTime, elapsedTime, intersects));

    renderer.render(scene, camera);

    tickRequest = window.requestAnimationFrame(tick);
  }

  function updateRenderer(newSizes: Sizes) {
    renderer.setSize(newSizes.width, newSizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  function getWorldSizes() {
    const vFOV = THREE.MathUtils.degToRad(camera.fov);
    const height = 2 * Math.tan(vFOV / 2) * distance;
    const width = height * camera.aspect;

    return {
      height,
      width
    };
  }

  function updateCamera(s: Sizes) {
    camera.aspect = s.width / s.height;
    camera.updateProjectionMatrix();
  }

  function onPointerMove(event: PointerEvent) {
    pointer.x = (event.clientX / canvas.clientWidth) * 2 - 1;
    pointer.y = -(event.clientY / canvas.clientHeight) * 2 + 1;
  }

  function onClick(event: MouseEvent) {
    pointer.x = (event.clientX / canvas.clientWidth) * 2 - 1;
    pointer.y = -(event.clientY / canvas.clientHeight) * 2 + 1;
  }
}
