interface InputTrackerOptions {
  lerpFactor: number;
  decayFactor: number;
}

export default function useInputTracker(
  options: InputTrackerOptions = { lerpFactor: 0.02, decayFactor: 0.1 }
) {
  const state = {
    isPaused: false,
    lastTimestamp: 0,
    lastPosition: { x: 0, y: 0 },
    currentSpeed: { x: 0, y: 0, preferred: 0 },
    lerpFactor: options.lerpFactor,
    decayFactor: options.decayFactor, // Adjust this for the desired decay rate
    wheelInputSensitivity: 1,
    touchInputSensitivity: 5
  };

  window.addEventListener('touchmove', handleTouchInput, { passive: false });
  window.addEventListener('wheel', handleWheelInput);

  return {
    getCurrentSpeed,
    destroy,
    pause,
    start,
    clearState
  };

  function pause() {
    state.isPaused = true;
    clearState();
  }

  function start() {
    state.isPaused = false;
  }

  function clearState() {
    state.lastTimestamp = 0;
    state.lastPosition = { x: 0, y: 0 };
    state.currentSpeed = { x: 0, y: 0, preferred: 0 };
  }

  function handleTouchInput(event: TouchEvent) {
    if (state.isPaused) {
      return;
    }

    const { currentTimestamp, deltaTime } = getDeltaTime();

    const touch = event.touches[0];

    const currentPosition = {
      x: touch.clientX * state.touchInputSensitivity,
      y: -touch.clientY * state.touchInputSensitivity
    };

    const deltaX = currentPosition.x - state.lastPosition.x;
    const deltaY = currentPosition.y - state.lastPosition.y;

    if (deltaTime > 0) {
      state.currentSpeed.x = lerp(state.currentSpeed.x, deltaX / deltaTime, state.lerpFactor);
      state.currentSpeed.y = lerp(state.currentSpeed.y, deltaY / deltaTime, state.lerpFactor);

      state.currentSpeed.preferred = state.currentSpeed.y;
    }

    state.lastTimestamp = currentTimestamp;
    state.lastPosition = currentPosition;
  }

  function handleWheelInput(event: WheelEvent) {
    if (state.isPaused) {
      return;
    }

    const { currentTimestamp, deltaTime } = getDeltaTime();

    if (deltaTime > 0) {
      state.currentSpeed.x = 0;
      // state.currentSpeed.x = lerp(state.currentSpeed.x, event.deltaX / deltaTime, state.lerpFactor);
      state.currentSpeed.y = lerp(state.currentSpeed.y, event.deltaY / deltaTime, state.lerpFactor);

      state.currentSpeed.preferred = state.currentSpeed.y;
    }

    state.lastTimestamp = currentTimestamp;
  }

  function getDeltaTime() {
    const currentTimestamp = Date.now();
    const deltaTime = currentTimestamp - state.lastTimestamp;

    return {
      currentTimestamp,
      deltaTime
    };
  }

  function updateDecay() {
    state.currentSpeed.x *= 1 - state.decayFactor;
    state.currentSpeed.y *= 1 - state.decayFactor;
    state.currentSpeed.preferred *= 1 - state.decayFactor;
  }

  function lerp(a: number, b: number, t: number): number {
    return a + t * (b - a);
  }

  function getCurrentSpeed(): { x: number; y: number; preferred: number } {
    updateDecay();

    return {
      x: state.currentSpeed.x,
      y: state.currentSpeed.y,
      preferred: state.currentSpeed.preferred
    };
  }

  function destroy() {
    window.removeEventListener('touchmove', handleTouchInput);
    window.removeEventListener('wheel', handleWheelInput);
  }
}
