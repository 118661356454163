import { default as _91slug_93FNbD9oDbfEMeta } from "/opt/render/project/src/pages/[slug].vue?macro=true";
import { default as indexYy8SidDWaAMeta } from "/opt/render/project/src/pages/index.vue?macro=true";
import { default as _91slug_93LMgaTbTqRgMeta } from "/opt/render/project/src/pages/portfolio/[slug].vue?macro=true";
export default [
  {
    name: _91slug_93FNbD9oDbfEMeta?.name ?? "slug",
    path: _91slug_93FNbD9oDbfEMeta?.path ?? "/:slug()",
    meta: _91slug_93FNbD9oDbfEMeta || {},
    alias: _91slug_93FNbD9oDbfEMeta?.alias || [],
    redirect: _91slug_93FNbD9oDbfEMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexYy8SidDWaAMeta?.name ?? "index",
    path: indexYy8SidDWaAMeta?.path ?? "/",
    meta: indexYy8SidDWaAMeta || {},
    alias: indexYy8SidDWaAMeta?.alias || [],
    redirect: indexYy8SidDWaAMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93LMgaTbTqRgMeta?.name ?? "portfolio-slug",
    path: _91slug_93LMgaTbTqRgMeta?.path ?? "/portfolio/:slug()",
    meta: _91slug_93LMgaTbTqRgMeta || {},
    alias: _91slug_93LMgaTbTqRgMeta?.alias || [],
    redirect: _91slug_93LMgaTbTqRgMeta?.redirect,
    component: () => import("/opt/render/project/src/pages/portfolio/[slug].vue").then(m => m.default || m)
  }
]