import * as THREE from 'three';
import ThreeBase from '~/webgl/ThreeBase';
import { FloatingPlanesExperience } from '~/webgl/floating-planes/FloatingPlanesExperience';
import type { Experience } from '~/webgl/floating-planes/FloatingPlanes';

export default function (canvas: HTMLCanvasElement): Experience {
  const base = ThreeBase(canvas);
  base.scene.background = new THREE.Color('#fef9f2');

  const floatingPlanesExperience = FloatingPlanesExperience(base);

  /* on tick */
  base.addOnTick((dT, eT) => {});

  return { type: 'default', floatingPlanesExperience };
}
