<template>
  <div class="app">
    <div id="outer" class="outer"></div>
    <InfiniteGallery />
    <div id="smooth-content">
      <NuxtPage class="nuxt-page" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ScrollSmoother } from 'gsap/ScrollSmoother';

import InfiniteGallery from '~/components/infinite-gallery/InfiniteGallery.vue';

const nuxtApp = useNuxtApp();

const $ScrollSmoother = ref<null | ScrollSmoother>(null);

nuxtApp.provide('ScrollSmoother', $ScrollSmoother);

const { data: siteData, error } = await useAsyncGql({
  operation: 'GetSiteData'
});

if (siteData.value._site?.globalSeo?.fallbackSeo) {
  const locale = siteData.value._site.locales[0];

  useHead({
    title: siteData.value._site.globalSeo.siteName,
    htmlAttrs: {
      lang: locale.replaceAll('_', '-'),
      dir: 'ltr'
    },
    meta: [
      {
        name: 'description',
        content: siteData.value._site.globalSeo.fallbackSeo.description
      },
      {
        name: 'title',
        content: siteData.value._site.globalSeo.fallbackSeo.title
      },
      {
        name: 'robots',
        content: siteData.value._site.noIndex ? 'noindex' : 'index'
      }
    ]
  });
}
</script>

<style scoped lang="scss">
.app {
  > .outer {
    z-index: 120;
  }
}
</style>
