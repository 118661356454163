<template>
  <div class="app-error">
    <main class="main">
      <div class="content">
        <h1 class="title">The page you are looking for does not exist.</h1>
        <button @click="handleError()" class="button-primary -light">
          <span class="text">Back to Home</span>
        </button>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
function handleError() {
  clearError({ redirect: '/' });
}
</script>

<style scoped lang="scss">
.app-error {
  color: var(--color-white);
  height: 100svh;
  background:
    linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%),
    url('/404.webp'),
    lightgray 50% / cover no-repeat;
  background-size: cover;

  > .main {
    padding: 2rem var(--base-component-padding-inline);
    height: 100%;
    display: flex;
    align-items: center;

    @media (--vs) {
    }

    @media (--vl) {
    }

    > .content {
      max-width: var(--size-landscape-default-width);
      margin: auto;

      @media (--vl) {
        padding-bottom: 10svh;
      }

      > .title {
        @include text-3xl();

        padding-bottom: 2rem;

        @media (--vs) {
        }

        @media (--vl) {
        }
      }
    }
  }
}
</style>
