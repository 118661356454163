import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetAllPortfolioProjects","GetMainNavigation","GetPageBySlug","GetPortfolioProjectBySlug","GetSiteData"]}
export const GqlGetAllPortfolioProjects = (...params) => useGql()('GetAllPortfolioProjects', ...params)
export const GqlGetMainNavigation = (...params) => useGql()('GetMainNavigation', ...params)
export const GqlGetPageBySlug = (...params) => useGql()('GetPageBySlug', ...params)
export const GqlGetPortfolioProjectBySlug = (...params) => useGql()('GetPortfolioProjectBySlug', ...params)
export const GqlGetSiteData = (...params) => useGql()('GetSiteData', ...params)