import type { ComputedRef, Ref } from 'vue';
import * as WebglExperience from '~/webgl/Experience';
import type { Experience } from '~/webgl/floating-planes/FloatingPlanes';

export type Webgl = {
  init: (canvas: HTMLCanvasElement) => void;
  isReady: ComputedRef<boolean>;
  webglExperience: Ref<Experience | null>;
};

export default function (): Webgl {
  const webglExperience = ref<Experience | null>(null);
  // const preferredMotion = usePreferredReducedMotion();

  const isReady = computed(function () {
    return webglExperience.value !== null;
  });

  return {
    init,
    isReady,
    webglExperience
  };

  function init(canvas: HTMLCanvasElement) {
    webglExperience.value = WebglExperience.default(canvas);
  }
}
