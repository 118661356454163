import gsap from 'gsap';

export default function (
  elements: {
    page: HTMLElement;
    headerImage: Element | null;
    title: Element | null;
  },
  finalTextColor: string = '#fff',
  done: () => void
) {
  elements.page.classList.add('-in-page-transition');
  elements.page.style.setProperty('--title-height', `${String(elements.title?.clientHeight)}px`);

  if (!elements.headerImage || !elements.title) {
    return done();
  }

  const tl = gsap.timeline({
    paused: true,
    onComplete: function () {
      done();
    }
  });

  tl.play();

  tl.to(elements.headerImage, {
    top: -100,
    ease: 'circ.out',
    duration: 1.1
  })
    .to(
      elements.title,
      {
        top: window.innerHeight - elements.title.clientHeight - window.innerHeight * 0.1,
        duration: 1,
        ease: 'circ.out'
      },
      '<'
    )
    .to(
      elements.headerImage,
      {
        top: window.innerHeight * -1 - 100,
        ease: 'power2.out',
        duration: 1
      },
      '<+=1.1'
    )
    .to(
      elements.title,
      {
        top: window.innerHeight * 0.1,
        ease: 'power2.out',
        duration: 1,
        color: finalTextColor
      },
      '<'
    )
    .fromTo(
      "[data-fade-in='true']",
      {
        y: window.innerHeight
      },
      {
        y: 0,
        ease: 'power2.out',
        delay: 0.1,
        duration: 1
      },
      '<'
    );
}
