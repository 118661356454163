export type Cursor = {
  x: number;
  y: number;
};
export default function useCursorPosition(s: { width: number; height: number }) {
  const cursor: Cursor = {
    x: 0,
    y: 0
  };

  const status = {
    width: s.width,
    height: s.height
  };

  window.addEventListener('mousemove', function (e: MouseEvent) {
    updateCursorPosition(e.clientX / status.width - 0.5, e.clientY / status.height - 0.5);
  });

  return {
    cursor,
    updateSizes
  };

  function updateCursorPosition(x: number, y: number) {
    cursor.x = x;
    cursor.y = y;
  }

  function updateSizes(s: { width: number; height: number }) {
    status.width = s.width;
    status.height = s.height;
  }
}
