import * as THREE from 'three';
import type { ThreeBase } from '~/webgl/ThreeBase';
import focusLandscape from '~/webgl/floating-planes/focus_landscape@2x.png?url';
import focusPortrait from '~/webgl/floating-planes/focus_portrait@2x.png?url';
import focusLandscapeVideo from '~/webgl/floating-planes/focus_video_landscape@2x.png?url';
import focusPortraitVideo from '~/webgl/floating-planes/focus_video_portrait@2x.png?url';
import * as FloatingPlane from '~/webgl/floating-planes/FloatingPlane';
import gsap from 'gsap';
import useInputTracker from '~/webgl/useInputTracker';
import type {
  FloatingPlaneDataImage,
  FloatingPlaneDataVideo,
  FloatingPlaneInputData,
  FloatingPlaneItem
} from '~/webgl/floating-planes/FloatingPlanes';

export function FloatingPlanesExperience(base: ThreeBase) {
  const isTouch = isTouchDevice();
  const router = useRouter();

  const textureLoader = new THREE.TextureLoader();

  const textures = {
    focusLandcape: textureLoader.load(focusLandscape),
    focusPortrait: textureLoader.load(focusPortrait),
    focusLandcapeVideo: textureLoader.load(focusLandscapeVideo),
    focusPortraitVideo: textureLoader.load(focusPortraitVideo)
  };

  const parameters = {
    zLength: 0,
    wheelSensitivity: 0.001,
    speed: 0.0015,
    cameraSensitivity: isTouch ? 0 : 20
  };

  const status = {
    worldSizes: base.getWorldSizes(),
    zProgress: 0,
    opacity: 1,
    isPaused: false,
    isObjectIntersecting: false,
    isInitialised: false,
    gap: 0,
    gapPercent: 0,
    currentSpeed: parameters.speed,
    cameraParallax: {
      x: 0,
      y: 0
    }
  };

  const planes: {
    value: FloatingPlaneItem[];
  } = {
    value: []
  };

  const planeGroup = new THREE.Group();
  planeGroup.position.set(0, 0, -100);
  base.scene.add(planeGroup);

  base.scene.background = new THREE.Color('#ffffff');

  const InputTracker = useInputTracker({ lerpFactor: 0.15, decayFactor: 0.07 });

  const windowEvents = WindowEvents();

  document.body.addEventListener('click', windowEvents.onClick);

  return { init, play, pause };

  function unsetHighlightedPlanes() {
    planes.value.forEach((p) => {
      p.onIntersecting(false);
    });
  }

  function toggleVisibility(show: boolean) {
    if (show) {
      base.canvas.style.visibility = 'visible';
    } else {
      base.canvas.style.visibility = 'hidden';
    }
  }

  function play(options: { delay?: number } = { delay: 0 }) {
    showPlanes();
    toggleVisibility(true);

    if (options.delay) {
      status.currentSpeed = 0;

      setTimeout(function () {
        status.currentSpeed = parameters.speed;
      }, options.delay);
    }

    status.isPaused = false;
    InputTracker.start();
  }

  function pause(hide = false) {
    status.isPaused = true;

    toggleVisibility(!hide);

    InputTracker.pause();
    InputTracker.clearState();
  }

  async function init(data: FloatingPlaneInputData[]) {
    status.isInitialised = true;

    parameters.zLength = data.length * 1.5;
    base.camera.position.z = parameters.zLength;

    const planesData = await getDataFromElements(data);
    planes.value = getPlanesFromData(planesData);

    status.gap = parameters.zLength / (planes.value.length - 1);
    status.gapPercent = 1 / (planes.value.length - 1);

    base.setIntersectionsObjects(planes.value.map((p) => p.intersectionPlane));

    const sequence = [
      { x: 1, y: -1 },
      { x: -1, y: 1 },
      { x: 1, y: 1 },
      { x: -1, y: -1 }
    ];

    planes.value.forEach((p, index) => {
      const s = sequence[index % sequence.length];

      p.init(
        parameters.zLength / planes.value.length,
        status.worldSizes.width * s.x * parameters.zLength * 0.3,
        status.worldSizes.height * s.y * parameters.zLength * 0.3
      );

      planeGroup.add(p.group);
    });

    update(0, 0, []);

    const tl = gsap.timeline({});

    tl.to(planeGroup.position, {
      z: 0,
      duration: 2.5,
      ease: 'power4.out',
      onComplete: function () {
        base.addOnTick(update);
      }
    }).fromTo(
      planeGroup.children.map((i) => i.scale),
      {
        y: 0,
        x: 0,
        z: 0
      },
      {
        y: 1,
        x: 1,
        z: 1,
        // duration: 2.5,
        ease: 'power4.out'
      },
      '<'
    );

    function update(dT: number, eT: number, intersections: THREE.Intersection[]) {
      if (status.isPaused) {
        return;
      }

      const currentSpeed = InputTracker.getCurrentSpeed();
      status.zProgress += currentSpeed.preferred * 0.02;

      const intersectingObject = intersections[0]?.object || null;
      const intersectingPlane = planes.value.find(
        (p) => p.intersectionPlane === intersectingObject
      );
      const nonIntersectingObjects = planes.value.filter((i) => i !== intersectingPlane);

      if (intersectingPlane) {
        intersectingPlane.onIntersecting(true);
      }

      nonIntersectingObjects.forEach((p) => {
        p.onIntersecting(false);
      });

      status.isObjectIntersecting = !!intersectingObject;

      if (!status.isPaused && !status.isObjectIntersecting) {
        status.zProgress += parameters.speed;
      }

      const parallax = {
        x: base.cursor.x,
        y: base.cursor.y
      };

      planes.value.forEach(function (p, index) {
        p.update(status.zProgress, parameters.zLength, status.opacity, parallax, index === 0);
      });

      base.camera.rotation.y = THREE.MathUtils.lerp(
        base.camera.rotation.y,
        base.cursor.x * -Math.PI * parameters.cameraSensitivity * 0.0001,
        0.1
      );
      base.camera.rotation.x = THREE.MathUtils.lerp(
        base.camera.rotation.x,
        base.cursor.y * -Math.PI * parameters.cameraSensitivity * 0.0001,
        0.1
      );
    }
  }

  function getHoveredPlane() {
    const plane = planes.value.find((p) => p.status.isIntersecting) ?? null;

    if (!plane) {
      return null;
    }

    const planeIndex = planes.value.indexOf(plane);
    const nextPlane = planes.value[planeIndex - 1] ?? planes.value[planes.value.length - 1];

    return {
      index: planeIndex,
      plane,
      nextPlane
    };
  }

  function hidePlanes(
    options: {
      reorderPosition: number;
      first?: FloatingPlaneItem;
      second?: FloatingPlaneItem;
    } = { reorderPosition: 1 },
    callback?: () => void
  ) {
    const reorderedPlanes = reorderArray(planes.value, options.reorderPosition + 1);
    const meshes = reorderedPlanes
      .reverse()
      .slice(0, 3)
      .map((p) => p.group.position);

    const tl = gsap.timeline({});

    tl.to(meshes, {
      y: status.worldSizes.height * 4,
      stagger: 0.2,
      ease: 'circ.in',
      duration: 1.15
    }).call(callback ?? function () {}, [], '<+=0.5');
  }

  function showPlanes() {
    gsap.to(base.canvas, {
      top: 0,
      duration: 0.75,
      ease: 'circ.inOut'
    });
  }

  function WindowEvents() {
    return { onClick };

    async function onClick(e: Event) {
      if (status.isPaused) {
        return;
      }

      const hoveredPlane = getHoveredPlane();

      if (hoveredPlane) {
        pause();

        hidePlanes(
          {
            reorderPosition: hoveredPlane.index
          },
          function () {
            router.push(`/portfolio/${hoveredPlane.plane.data.slug}`);

            unsetHighlightedPlanes();
          }
        );
      }
    }
  }

  async function getDataFromElements(data: FloatingPlaneInputData[]) {
    return await Promise.all(
      data.map(
        async (d, index): Promise<FloatingPlaneDataVideo | FloatingPlaneDataImage | null> => {
          const data = {
            title: d.name,
            slug: d.slug,
            assetData: d.assetData
          };

          if (d.assetData.type === 'video' && d.video) {
            const video = document.createElement('video');
            video.crossOrigin = 'anonymous';
            video.src = d.video.src;
            video.autoplay = true;
            video.playsInline = true;
            video.loop = true;
            video.muted = true;
            video.play();

            const focusTexture =
              d.assetData.aspectRatio < 1
                ? textures.focusPortraitVideo
                : textures.focusLandcapeVideo;

            const additionalData = {
              type: 'video',
              videoTexture: new THREE.VideoTexture(video),
              focusTexture
            };

            return {
              ...data,
              ...additionalData
            } as FloatingPlaneDataVideo;
          }

          if (d.assetData.type === 'image' && d.image) {
            const imageTexture = await textureLoader.loadAsync(d.image.src);
            const blurryImageTexture = await textureLoader.loadAsync(d.image.blurrySrc);
            const focusTexture =
              d.assetData.aspectRatio < 1 ? textures.focusPortrait : textures.focusLandcape;

            const additionalData = {
              type: 'image',
              imageTexture,
              blurryImageTexture,
              focusTexture
            };

            return {
              ...data,
              ...additionalData
            } as FloatingPlaneDataImage;
          }

          return null;
        }
      )
    );
  }

  function getPlanesFromData(data: (FloatingPlaneDataVideo | FloatingPlaneDataImage)[]) {
    return data.map((i, index) => {
      return FloatingPlane.default(i, index, base.getWorldSizes());
    });
  }
}

// utility function
function reorderArray<T>(data: T[], index: number) {
  return data.slice(index).concat(data.slice(0, index));
}
